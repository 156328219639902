.pricing-heading {
  text-align: center;
  padding: 2.5rem 0;
}
.pricing-heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
}
.pricing-heading h1 {
  font-size: 2.8125rem;
  margin: 1.25rem 0;
  text-transform: capitalize;
}
.Pricing .items {
  padding: 2.5rem;
  text-align: center;
}
.Pricing .items h1 {
  font-size: 50px;
  margin: 20px 0;
  color: #1eb2a6;
}
.Pricing h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}
.Pricing p {
  color: grey;
  margin: 40px 0;
}
