.testimonial-heading {
  text-align: center;
  padding: 2.5rem 0;
}
.testimonial-heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
}
.testimonial-heading h1 {
  font-size: 2.8125rem;
  margin: 1.25rem 0;
  text-transform: capitalize;
}
.testimonial .items {
  padding: 1.875rem;
  transition: all 0.5s linear;
}
.testimonial .img {
  position: relative;
}
.testimonial img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonial i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
}
.testimonial .name {
  margin: 25px;
}

.testimonial .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonial .name span {
  color: #1eb2a6;
}
.testimonial p {
  margin-top: 20px;
  color: grey;
  transition: all 0.5s linear;
}
.testimonial .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.testimonial .items:hover p,
.testimonial .items:hover span {
  color: #fff;
}
