.coursesList {
  text-align: center;
}
.coursesList .coursesList-header {
  text-align: center;
  padding: 2.5rem 0;
}
.coursesList-header h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
}
.coursesList-header h1 {
  font-size: 2.8125rem;
  margin: 1.25rem 0;
  text-transform: capitalize;
}
.coursesList .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 1.875rem 1.25rem;
  transition: all 0.5s linear;
}
.coursesList .img {
  width: 5rem;
  height: 5rem;
  margin: auto;
  position: relative;
}
.coursesList img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesList .box .show {
  opacity: 0;
}
.coursesList h1 {
  font-size: 500;
  font-size: 1.25rem;
  margin: 1.25rem 0;
  line-height: 1.875rem;
}
.coursesList span {
  background-color: #f8f8f8;
  padding: 0.3125rem 1.25rem;
  font-weight: 500;
  font-size: 0.9375rem;
  border-radius: 0.3125rem;
  color: #1eb2a6;
}
.coursesList .box:hover {
  background-color: #1eb2a6;
  border-radius: 5px;
  cursor: pointer;
}
.coursesList .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.coursesList .box:hover h1 {
  color: #fff;
}
