.courses-heading {
  text-align: center;
  padding: 2.5rem 0;
}
.courses-heading h3 {
  font-weight: 600;
  color: #1eb2a6;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.courses-heading h1 {
  font-size: 2.8125rem;
  margin: 1.25rem 0;
  text-transform: capitalize;
}
.coursesCard {
  margin: 3.125rem 0;
}
.coursesCard .items {
  background-color: #fff;
  padding: 1.875rem;
  text-align: center;
}
.coursesCard .img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  padding: 0.9375rem;
  background-color: #1eb2a6;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard .text {
  margin-left: 0.9375rem;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.8125rem;
}
.coursesCard .rate {
  margin: 1.25rem 0;
  color: #1eb2a6;
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: gray;
}

.coursesCard .details .dimg img {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1.25rem;
}
.coursesCard .details span {
  color: #1eb2a6;
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 1.875rem 0;
  background-color: #f8f8f8;
  padding: 0.625rem;
}
.coursesCard .price h3 {
  font-weight: 500;
  color: #1eb2a6;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #1eb2a6;
  width: 100%;
  transition: all 0.5s linear;
}
