footer {
  background-color: #eeeeee;
}
footer .container {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 4fr 3fr;
  grid-gap: 30px;
}
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
footer .logo span {
  color: #1eb2a6;
  font-size: 14px;
}
footer .logo p {
  color: grey;
  margin: 30px 0 15px 0;
}
footer .logo .icon {
  background-color: #1eb2a6;
  color: white;
  margin-right: 10px;
}
footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 20px;
}
footer .link li::before {
  content: "⟶";
  color: #1eb2a6;
  margin-right: 5px;
}
footer .items {
  margin-bottom: 30px;
}
footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}
footer .items span,
footer .items i {
  font-size: 12px;
  color: #1eb2a6;
  margin-right: 5px;
  text-transform: capitalize;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  width: 60px;
  font-size: 20px;
  color: #1eb2a6;
}
.legal {
  text-align: center;
  padding: 50px 0;
  color: grey;
  background-color: #e9e9e9;
}
.legal p {
  font-size: 15px;
}
.legal i {
  color: #1eb2a6;
}
