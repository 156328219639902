@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f8f8;
}
/* Global Styles */
.container {
  max-width: 85%;
  margin: auto;
}
.padding {
  padding: 80px 0;
}
.flexSB {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #1eb2a6;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #1eb2a6;
  color: #fff;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
button {
  background-color: #fff;
  padding: 1.0625rem 1.875rem;
  color: #1eb2a6;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
.row {
  width: 50%;
}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
}
.primary-btn {
  background-color: #1eb2a6;
  color: #fff;
}
.margin {
  margin-top: 40.3%;
}
/* Heading */

.heading {
  text-align: center;
  padding: 40px 0;
}
.heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
}
.heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}
.heading p {
  line-height: 30px;
  font-size: 18px;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
.outline-btn:hover {
  background-color: #1eb2a6;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
}
